@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing    : antialiased;
  -moz-osx-font-smoothing   : grayscale;
  // background             : #0b0e24;
  -webkit-scroll-behavior   : smooth;
  -moz-osx-scroll-behavior  : smooth;
  -moz-scroll-behavior      : smooth;
  scroll-behavior           : smooth;
  overflow-x                : hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}