.App {
  text-align: center;

  .lined_paper_container {
    background-image     : linear-gradient(rgba(0, 0, 0, 0.3) 1px, transparent 0px);
    background-position-y: 28px;
  }

  @media (min-width: 0px) {
    .lined_paper_container {
      background-size: 100% 1.30em;
      font-size      : 10px;
      line-height    : 1.30em !important;
    }
  }

  @media (min-width: 640px) {
    .lined_paper_container {
      background-size: 100% 1.24em;
      font-size      : 14px;
      line-height    : 1.24em !important;
    }
  }

  @media (min-width: 768px) {
    .lined_paper_container {
      background-size: 100% 1.55em;
      font-size      : 16px;
      line-height    : 1.55em !important;
    }
  }



  @keyframes g_Animation {
    0% {
      background-position: 0 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0 50%;
    }
  }

  .gradient_Animation {
    animation: g_Animation 3s ease-in-out infinite;
  }


  .thin_scrollbar {
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #888;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(9, 144, 255);
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin            : 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }








  // FAQ
  .tab-content {
    max-height        : 0;
    -webkit-transition: max-height .35s;
    -o-transition     : max-height .35s;
    transition        : max-height .35s;
  }

  .tab {
    box-sizing: border-box;
    transition: all 0.3s;
  }

  .tab input:checked~.tab-content {
    max-height: 100vh;
  }

  .tab input:checked+label {
    // border-top-width: 1.5px;
    // border-color    : #6574cd;
    // color           : #6574cd;
    // background-color: #8184aca4;
  }

  .tab input:checked+label p {
    font-weight: 900;
    color      : #3b82f6;

  }

  .downArrow {
    transition: all 0.3s ease-in;
  }

  .tab input:checked~.downArrow {
    transition: all 0.2s ease-in;
    transform : rotate(180deg);
  }


  @keyframes notibar_Animation {
    0% {
      -moz-transform   : translate(-10%, 0);
      -webkit-transform: translate(-10%, 0);
      transform        : translate(-10%, 0);
    }

    100% {
      -moz-transform   : translate(100%, 0);
      -webkit-transform: translate(100%, 0);
      transform        : translate(100%, 0);
    }
  }


  .notibar_text {
    animation: notibar_Animation 15s linear infinite;

  }

}